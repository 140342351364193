import styled from "styled-components";
import { colors } from './colors'
import { fontSize } from './typography'
import right from "../Icons/right.svg"

const ViewCourse = styled.div`
  // border: ${colors.corporateIntroText} solid 1px;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 0 20px;
  h5{
    margin: 10px 0;
  }
`

const ViewCourseTitle = styled.h3`
  font-size: ${fontSize.h2};
  margin-bottom: 0;
  color: ${colors.corporateIntroText};
`

const ViewCourseGo = styled.h5`
  ::after{
      mask: url('${right}');
      margin-left: 10px;
      display: inline-block;
      height: 11px;
      width: 7px;
      mask-size: cover;
      background-color: ${colors.corporateHeader};
      content: "";
    }
`
export { ViewCourse, ViewCourseTitle, ViewCourseGo }
