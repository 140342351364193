// Icons
import { ReactComponent as UpIcon } from '../Icons/up.svg'
import { ReactComponent as DownIcon } from '../Icons/down.svg'
import { ReactComponent as RightIcon } from '../Icons/right.svg'
import { ReactComponent as LeftIcon } from '../Icons/left.svg'
import { ReactComponent as Link } from '../Icons/link.svg'
import { ReactComponent as Terminal } from '../Icons/terminal.svg'
import { ReactComponent as Video } from '../Icons/video.svg'
import { ReactComponent as ArrowRightIcon } from '../Icons/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from '../Icons/arrow-left.svg'
import { ReactComponent as Clock } from '../Icons/clock.svg'
import { ReactComponent as Copy } from '../Icons/copy.svg'
import { ReactComponent as Envelope } from '../Icons/envelope.svg'
import { ReactComponent as Python } from '../Icons/python.svg'
import { ReactComponent as Chalkboard } from '../Icons/chalkboard.svg'
import { ReactComponent as Close } from '../Icons/close.svg'


/**
 * import svg icons for display
 * 
 * @param {*} props 
 * @props {string} icon - name of icon
 */

function Icon(props){
  return(
    <>
      {props.icon === "up" &&
        <UpIcon />
      }
      {props.icon === "right" &&
        <RightIcon />
      }
      {props.icon === "down" &&
        <DownIcon />
      }
      {props.icon === "left" &&
        <LeftIcon />
      }
      {props.icon === "terminal" &&
        <Terminal />
      }
      {props.icon === "video" &&
        <Video />
      }
      {props.icon === "link" &&
        <Link />
      }
      {props.icon === "arrow-right" &&
        <ArrowRightIcon />
      }
      {props.icon === "arrow-left" &&
        <ArrowLeftIcon />
      }
      {props.icon === "clock" &&
        <Clock />
      }
      {props.icon === "copy" &&
        <Copy />
      }
      {props.icon === "envelope" &&
        <Envelope />
      }
      {props.icon === "python" &&
        <Python />
      }
      {props.icon === "chalkboard" &&
        <Chalkboard />
      }
      {props.icon === "close" &&
        <Close />
      }
    </>
  )
}

export { Icon }