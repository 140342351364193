import styled from "styled-components";
import { colors } from './colors'
import { weight, fontSize } from "./typography"

const AlertMsgSpace = styled.div`
  background-color: ${colors.alertBG};
  padding: 20px;
`

const AlertText = styled.h6`
  color: white;
  font-weight: ${weight.regular};
  font-size: ${fontSize.p};
`

export { AlertMsgSpace, AlertText }