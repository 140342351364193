// clicking out side of modal from https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82

import { useRef, useEffect } from 'react'

import { Icon } from '../components/Icon'

// styles
import { ModalBG, ModalContent, ModalClose } from "../styles/modal"

function Modal(props){
  const modalNode = useRef()

  const handleClick = e => {
    if (modalNode.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click 
    props.onClose(false)
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  return(
    <ModalBG>
      <ModalContent ref={modalNode}>
        <ModalClose onClick={event => props.onClose(false)}>
          <Icon icon="close" />
        </ModalClose>
        { props.children }
      </ModalContent>
    </ModalBG>
  )
}

export default Modal