import styled from "styled-components";
import { colors } from './colors'
import { weight, fontSize } from './typography'

// icons
import copy from "../Icons/copy.svg"

const PopUpAlert = styled.div`
  font-size: ${fontSize.small};
  padding: 7px 10px;
  display: block;
  position: absolute;
  top: -32px;
  z-index: 2;
  background-color: ${colors.copyPopUpBG};
  color: white;
  padding: 6px 13px;
  border-radius: 4px;
  ::after{
    position: absolute;;
    content: "";
    display: block;
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid ${colors.copyPopUpBG};
    left: 45%;
    top: 25px;
  }
`

const CopyCode = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  position: relative;
  button.copy-code{
    border-right: solid 1px ${colors.defaultBorder};
    border-left: solid 1px ${colors.defaultBorder};
    border-bottom: solid 1px ${colors.defaultBorder};
    border-top: none;
    border-radius: 0 0 4px 4px;
    background: none;
    color: ${colors.linkColor};
    font-weight: ${weight.bold};
    font-size: ${fontSize.small};
    margin: 0 0 20px;
    padding: 4px 13px;
    position: relative;
    ::before{
      content: "";
      display: inline-block;
      margin-right: 8px;
      position: relative;
      top: 3px;
      mask: url('${copy}');
      background-color: ${colors.linkColor};
      height: 12px;
      width: 11px;
      transition: transform .2s ease-out;
    }
    :hover{
      ::before{
        transform: scale(1.3);
      }
    }
  }
`

export { PopUpAlert, CopyCode }