import SidebarSchedule from "./SidebarSchedule"

//hooks
import useFetchPageContent from "../hooks/useFetchPageContent"

function CourseSchedule(props){
  const schedule = useFetchPageContent("schedule", props.file).info
  if (!schedule) return null

  const workshops = schedule.filter(({ type }) => type == 'workshop').map((day,i) => {
    return <li key={`day${i}`}>
      <a href={`/day/${day.link}`}>{day.title}</a>
    </li>
  })

  return(
    <SidebarSchedule
      title="Course Schedule"
    >
      <h3>Details</h3>
      <ul>
        { workshops }
      </ul>
    </SidebarSchedule>
  )
}

export default CourseSchedule
