import { useState } from 'react'

import useInterval from '../hooks/useInterval'

function useShowPopUp(){
  const [showPopUp, setShowPopUp] = useState()

  const togglePopUp = () => {
    setShowPopUp(true)
  }

  useInterval(() => {
    setShowPopUp(false)
  }, 5000)

  return { showPopUp, togglePopUp }
}

export default useShowPopUp