import { useState, useEffect } from 'react'

function useRefreshContent(url){
  const [content, setContent] = useState()
  const [hasUpdated, setHasUpdated] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(url)
      const data = await result.text()
      setContent(data)
      setHasUpdated(false)
    }
    if (url){
      fetchData()
    }
  }, [url, hasUpdated])

  return {content, hasUpdated, setHasUpdated}
}

export default useRefreshContent