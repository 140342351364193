// styles
import { ScheduleTitle, ScheduleSpace } from '../styles/schedule'

function SidebarSchedule(props){
  return(
    <>
      <ScheduleTitle>{props.title}</ScheduleTitle> 
      <ScheduleSpace className="schedule"> {props.children} </ScheduleSpace>
    </>
  )
}

export default SidebarSchedule