import { AlertMsgSpace, AlertText } from '../styles/alert'

function Alert(props){
  return( 
    <AlertMsgSpace>
      <AlertText>{ props.children }</AlertText>
    </AlertMsgSpace>
  )
}

export default Alert