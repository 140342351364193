//components
import Notes from './Notes'
import CourseSchedule from './CourseSchedule'

//styles
import { NotesPageWrapper, NotesPageSpace } from '../styles/notesPage'
import { TwoColSpace, PageContent, SideBar, CourseInfo, Date, Title } from '../styles/page'

// hooks
import useFetchPageContent from "../hooks/useFetchPageContent"

function Course(props){

  //manage content
  const landingPage = useFetchPageContent("landingPage", props.file)
  const courseInfo = useFetchPageContent("courseInfo", props.file)
  const courseTitle = useFetchPageContent("courseTitle", props.file)
  const courseDate = useFetchPageContent("courseDate", props.file)

  const title = <><Date>{courseDate.info}</Date><Title>{courseTitle.info}</Title></>
  return(
    <NotesPageWrapper className="notes-page">
      <NotesPageSpace className="notes-page-space">
        <TwoColSpace className="notes-space">

          <SideBar>
            <CourseSchedule 
              file = {props.file}
            />
            <CourseInfo className="course-info"><Notes>{ courseInfo.info }</Notes></CourseInfo>
          </SideBar>

          <PageContent>
            
            
            <Notes
              before = { title }
              type="notes"
            >
              { landingPage.info }
            </Notes>
          </PageContent>
          
        </TwoColSpace>
      </NotesPageSpace>
    </NotesPageWrapper>
  )
}

export default Course