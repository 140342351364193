import { colors } from './colors'
import { headerDimensions } from './dimensions'
import { weight } from './typography'
import styled from "styled-components";

const NotesPageWrapper = styled.div`
  background-color: ${colors.embedBG};
  flex-grow: 1;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media (min-width: ${headerDimensions.twoLineBPmin}){
    height: calc(100vh - ${headerDimensions.headerHeight[0]});
  }
  @media (max-width: ${headerDimensions.twoLineBPmax}){
    height: calc(100vh - ${headerDimensions.headerHeight[1]});
  }
  @media (max-width: ${headerDimensions.shrinkSizeBP}){
    height: calc(100vh - ${headerDimensions.headerHeight[2]});
  }
`

const NotesPageSpace = styled.article`
  background-color: white;
  overflow-x: auto;
  box-shadow: -1px 0 4px rgba(0, 0, 0, 0.17);
  height: 100%;
  max-width: 1200px;
  @media (min-width: ${headerDimensions.twoLineBPmin}){
    width: calc(100% - 80px);
  }
  @media (max-width: ${headerDimensions.twoLineBPmax}){
    width: calc(100% - 40px);
  }
  @media (max-width: ${headerDimensions.shrinkSizeBP}){
    width: 100%;
  }
`

const NotesSpace = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const HeaderLinks = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  a{
    font-weight: ${weight.regular};
    color: ${colors.headerLink};
  }
  .icon-link{
    display: inline-block;
    margin-right: 30px;
    svg{
      color: ${colors.headerLink};
    }
  }
`

export { NotesPageWrapper, NotesPageSpace, NotesSpace, HeaderLinks }