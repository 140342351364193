import { Icon } from '../components/Icon'

//styles
import { IconLinkSpace } from '../styles/iconLink'

/**
 * Link and Icon (used in embeds)
 * 
 * @param {*} props 
 * @props {string} icon - name of icon displayed
 * @props {string} link - url link
 * @props {string} text - display text for link
 */

function IconLink(props) { 
  return (
    <IconLinkSpace className="icon-link">
      <Icon icon={props.icon} />
      {props.target && 
        <a href={props.link} target={props.target}>{props.text}</a>
      }
      {!props.target && 
        <a href={props.link}>{props.text}</a>
      }
      
    </IconLinkSpace>
  )
}

export { IconLink } 