import { colors } from './colors'
import styled, { css, keyframes } from "styled-components";
import { fontSize } from './typography'
import { headerDimensions } from './dimensions'
import python from "../Icons/python.svg"
import chalkboard from "../Icons/chalkboard.svg"

const block = css`
  padding: 40px;
  border-bottom: solid 1px ${colors.defaultBorder};
  h1, h2{
    color: ${colors.corporateHeader}
  }
  h2{
    font-size: ${fontSize.h1}
  }
  p{
    line-height: 2em;
  }
`
const dotSize = "3px"
const steam = keyframes`
  from {
    background-position: 5px 5px, 20px 20px; 
  } 
  to {
    background-position: 5px -24px, 20px -9px; 
  }
`
const Intro = styled.div`
  ${block}
  display: flex;
  
  background-color: ${colors.corporateIntroBG};
  color: ${colors.corporateIntroText};
  h1{
    font-size: ${fontSize.display};
    color: white;
  }
  @media (min-width: ${headerDimensions.twoLineBPmin}){
    flex-direction: row;
  }
  @media (max-width: ${headerDimensions.twoLineBPmax}){
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media (min-width: ${headerDimensions.twoLineBPmin}){
    h1 + p{
      font-size: ${fontSize.h3};
    }
  }
  .illustration{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    @media (max-width: ${headerDimensions.twoLineBPmax}){
      justify-content: center;
      border-top: solid 1px ${colors.corporateIntroText};
      margin-top: 20px;
    }
    ::after{
      content: "";
      height: 40px;
      width: 30px;
      display: inline-block;
      background-image: radial-gradient(${colors.teaSteamDot} ${dotSize}, transparent ${dotSize}),
                        radial-gradient(${colors.teaSteamDot} ${dotSize}, transparent ${dotSize});
      background-size:30px 30px;
      background-position: 5px 5px, 20px 20px; 
      position: relative;
      animation: ${steam} 1.5s infinite linear;
      @media (min-width: ${headerDimensions.twoLineBPmin}){
        left: -65px;
        top: -80px;
      }
      @media (max-width: ${headerDimensions.twoLineBPmax}){
        left: -45px;
        top: -50px;
      }
    }
  }
  .illu-desktop{
    margin: 20px 3px 40px 0;
    @media (min-width: ${headerDimensions.twoLineBPmin}){
      width: 300px;
    }
    @media (max-width: ${headerDimensions.twoLineBPmax}){
      width: 200px;
    }
  }
  .illu-teamug{
    @media (min-width: ${headerDimensions.twoLineBPmin}){
      width: 100px;
    }
    @media (max-width: ${headerDimensions.twoLineBPmax}){
      width: 66px;
    }
  }
`

const Courses = styled.div`
  ${block}
  .mailing-list h2{
    color: white;
  }
  .intro h2{
    ::before{
      mask: url('${chalkboard}');
      margin-right: 15px;
      display: inline-block;
      height: 30px;
      width: 40px;
      mask-size: cover;
      background-color: ${colors.corporateHeader};
      content: "";
    }
  }
  @media (min-width: ${headerDimensions.twoLineBPmin}){
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .mailing-list{
      width: 500px;
    }
    div:first-of-type{
      flex-shrink: 3;
    }
  }
`

const Services = styled.div`
  ${block}
  h2{
    ::before{
      mask: url('${python}');
      margin-right: 15px;
      display: inline-block;
      height: 45px;
      width: 40px;
      mask-size: cover;
      background-color: ${colors.corporateHeader};
      content: "";
    }
  }
`

const About = styled.div`
  ${block}
`
export { Intro, Courses, Services, About }