import { ScheuleEntryStyle, ScheduleTitle, ScheduleTime, ScheduleVolunteer } from '../styles/scheduleEntryStyle'
import { Link } from "react-router-dom";

/**
 * Display scheduled events
 * 
 * @param {*} props
 * @props {string} url - link to full module notes (optional)
 * @props {boolean} current - add extra design element for currently happening
 * @props {function()} updateModule - navigate to new module
 * @props {number} module - index of module
 * @props {string} title - name of event on schedule
 * @props {string} start - start time
 * @props {string} time - duration
 * @props {string} volunteer
 * @props {string} noteTaker
 */

function ScheduleEntry(props){
  const isModule = props.url ? "module" : "notModule"

  return(
    <ScheuleEntryStyle 
      className = "schedule-entry"
      isModule = { isModule }
    >
      <ScheduleTitle className="schedule-title" current={ props.current }>
        { props.url &&
          <Link 
            to={ location => `/notes/${props.link}` } 
          >
            { props.title }
          </Link>
        }
        { props.offsiteLink &&
          <a href={ props.offsiteLink } >
            { props.title }
          </a>
        }
        { (!props.url && !props.offsiteLink) && props.title }
      </ScheduleTitle>
      {(props.start || props.time) &&
        <ScheduleTime className="schedule-time">
          {props.start &&
            <li>Start: <b>{ props.start }</b></li>
          }
          {props.time &&
            <li>Time: <b>{ props.time }</b></li>
          }
        </ScheduleTime>
      }
      {(props.volunteer || props.noteTaker) &&
        <ScheduleVolunteer className="schedule-volunteer">
          {props.volunteer && 
            <li>Volunteer: { props.volunteer }</li>
          }
          {props.noteTaker && 
            <li>Note Taker: { props.noteTaker }</li>
          }
        </ScheduleVolunteer>
      } 
    </ScheuleEntryStyle>
  )
}

export default ScheduleEntry