import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"
import { robotoFamily, fontSize, weight } from './typography'
import { colors } from './colors'

export const Global = createGlobalStyle`
  ${normalize()}
  html {
    box-sizing: border-box;
    font-size: 14px;
    color: ${colors.defaultText};
    background-color: ${colors.background};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${robotoFamily};
  }
  h1, h2, h3, h4, h4, h6{
    color: black;
    margin: .5em 0;
  }
  h1{
    font-size: ${fontSize.h3};
    line-height: 1em;
  }
  
  h2{
    font-size: ${fontSize.h3};
  }
  
  h3{
    font-size: ${fontSize.h4};
    
  }
  
  h4{
    font-size: ${fontSize.h5};
  }
  
  h5{
    font-size: ${fontSize.h5};
  }
  h6{
    font-size: ${fontSize.h5};
  }
  p{
    font-size: ${fontSize.p};
    line-height: 1.6em;
  }
  a {
    font-weight: ${weight.bold};
    text-decoration: none;
    background: linear-gradient(to right, ${colors.linkColor} 0%, ${colors.linkColor} 100%);
    background-position: 0 100%;
    background-repeat: repeat-y;
    background-size: 0px 0px;
    color: ${colors.linkColor};
    transition: all .2s;
    transition-delay: .1s;
    :hover {
      color: white;
      background-size: 100% 4px;
    }
  }
  ul,ol{
    padding-left: 1em;
  }
  li{
    margin: 0;
    line-height: 1.6em;
  }
  table{
    width: 100%;
    /* border-top: solid 1px ${colors.defaultBorder};
    border-bottom: solid 1px ${colors.defaultBorder}; */
    border-collapse: collapse;
    margin: 10px 0 30px 0;
  }
  th {
    background-color: ${colors.tableHead};
    color: white;
    padding: 10px;
    text-align: left;
  }
  td{
    padding: 10px;
  }
  tr{
    :nth-of-type(2n-1){
      background-color: ${colors.tableStripe};
    }
  }
  code:not(.hljs){
    background-color: ${colors.codeColor};
    font-size: 95%;
    padding: 0.25em 0.4em;
    font-weight: bold;
  }
  form{
    width: 100%;
  }
  fieldset{
    border: none;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
  }
  label{
    display: block;
    color: ${colors.formLabel};
    font-weight: ${weight.bold};
    font-size: ${fontSize.small};
  }
  input{
    padding: 10px 20px;
    border: none;
    width: 100%;
    border-radius: 4px;
    background-color: ${colors.formInputBG};
  }
`