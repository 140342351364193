import styled, { css } from "styled-components"
import { colors } from "./colors"
import { fontSize } from "./typography"
import leftArrow from "../Icons/arrow-left.svg"
import rightArrow from "../Icons/arrow-right.svg"

import {Link} from "react-router-dom"

const iconSize = css`
  display: block;
  height: calc(1em + 1px);
  width: 1em;
  mask-size: cover;
  background-color: ${colors.linkColor};
  content: "";
  margin-top: 3px;
  :focus{
    outline: none;
  }
`
const link = css`
  display: flex;
  padding: 10px;
  width: 50%;
  line-height: 1.6em;
  font-size: ${fontSize.small};
  background-color: ${colors.asideNavBG};
  border: none;
  border-radius: 0;
  color: ${colors.defaultText};

  @media (max-height: 800px){
    .section-label{
      display: none;
    }
  }
`

const linkHover = css`
  background-size: 225%;
  transition: background-position .4s ease-out, color .4s ease-out;
  :hover{
    color: white;
    background-size: 225%;
    ::before{
      background-color: white;
    }
  }
  :focus{
    outline: none;
  }
`
const leftLinkHover = css`
  ${linkHover}
  background-image: conic-gradient(white 0deg 45deg, ${colors.linkColor} 45deg 135deg, white 135deg 360deg);
  background-position: left;
  :hover{
    background-position: right;
  }
`

const RightLinkHover = css`
  ${linkHover}
  background-image: conic-gradient(white 0deg 225deg, ${colors.linkColor} 225deg 315deg, white 315deg 0deg);
  background-position: right;
  :hover{
    background-position: left;
  }
`

const AsideNavSpace = styled.nav`
  background-color: ${colors.asideNavBG};
  width: 100%;
  ${(props) => props.border === "bottom" &&
  css `
    border-bottom: solid 1px ${colors.defaultBorder};
  `
  }
  ${(props) => props.border === "top" &&
  css `
    border-top: solid 1px ${colors.defaultBorder};
  `
  }
  
  display: flex;
  justify-content: space-between;
`

const PrevLink = styled(Link)`
  ${link}
  text-align: right;
  ::before{
    mask: url('${leftArrow}');
    ${iconSize}
    margin-right: 15px;
  }
  ${leftLinkHover}
`

const NextLink = styled(Link)`
  ${link}
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-end;
  ::before{
    content: "";
    mask: url('${rightArrow}');
    margin-left: 15px;
    ${iconSize}
  }
  :only-child{
    margin-left: 50%;
  }
  ${RightLinkHover}
`

const DayLink = styled.a`
  background-color: white;
  width: 100%;
  padding: 10px;
  border-top: solid 1px ${colors.defaultBorder};
  font-size: ${fontSize.small};
  display: flex;
  justify-content: center;
  @media (max-height: 800px){
    display: none;
  }
`

export { AsideNavSpace, PrevLink, NextLink, DayLink }