//styles
import { AsideNavSpace, PrevLink, NextLink, DayLink } from '../styles/asideNav'

/**
 * Navigation for notes or text content
 * 
 * @props {string} border - either top or bottom, css param to show border above or below the nav
 * @props {string} nextLabel - label for the next item in the nav
 * @props {number} nextLink - index of next item in an array of items to nav through
 * @props {string} prevLabel - label for the prev item in the nav
 * @props {number} prevLink - index of prev item in an array of items to nav through
 * @props {function()} updateDay - function to update currently displayed item
 * @props {string} label - label for nav item
 */
function AsideNav(props){
  let prevTo, nextTo, label
  if (props.label === 'Day'){
    prevTo = location => `/day/${props.prevLink}`
    nextTo = location => `/day/${props.nextLink}`
    label = "Session"
  } else if (props.label === "Notes"){
    prevTo = location => `/notes/${props.prevLink}`
    nextTo = location => `/notes/${props.nextLink}`
    label = "Module"
  }

  let daylink = `/day/${props.dayLink}`

  return(
    <>
    <AsideNavSpace className="aside-nav-space" border={props.border}>
      {props.prevLabel &&
        <PrevLink className="prev-link" to={ prevTo }>
          <div>Previous { label } <br />
          <b className="section-label">{props.prevLabel}</b></div>
        </PrevLink>
      }

      {props.nextLabel &&
        <NextLink className="next-link" to={ nextTo }>
          <div>Next { label } <br />
          <b className="section-label">{props.nextLabel}</b></div>
        </NextLink>
}
    </AsideNavSpace>
    {props.label === "Notes" && 
      <DayLink href={daylink}>
        Back to { props.title }
      </DayLink>
    }
    </>
  )
}

export default AsideNav
