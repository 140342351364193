// hooks
import useFetchPageContent from "../hooks/useFetchPageContent"

// styles
import { ViewCourse, ViewCourseTitle, ViewCourseGo } from "../styles/viewCourseLink"

function ViewCourseLink({ title, short, link }){

  return(
    <ViewCourse className="view-course">
      <ViewCourseTitle>{ title }</ViewCourseTitle>
      <ViewCourseGo><a href={ link }>View { short } Notes</a></ViewCourseGo>
    </ViewCourse>
  )
}

export default ViewCourseLink
