import { useParams } from "react-router-dom"

// components
import AsideNav from './AsideNav'
import DayNotes from './DayNotes'
import ModuleNotes from './ModuleNotes'

//styles
import { NotesPageWrapper, NotesPageSpace, NotesSpace } from '../styles/notesPage'

//hooks
import useGetDay from '../hooks/useGetDay'
import useFetchPageContent from "../hooks/useFetchPageContent"

function NotesPage(props){

  let { link, track } = useParams()
  // console.log('NotesPage', { props })
  props.file = `/python/content.json`

  let moduleID, schedule

  //manage content
  const info = useGetDay(link, props.file)
  const content = info.content
  const dayID = info.currDayIndex.current

  // course title
  const courseTitle = useFetchPageContent("courseTitle", props.file).info
  const blueJeans = useFetchPageContent("blueJeans", props.file).info
  const tmate = useFetchPageContent("tmate", props.file).info
  const jupyter = useFetchPageContent("jupyter", props.file).info

  let next, prev, nextLink, prevLink, update
  
  // set up prev and next
  if (props.type === "Notes" && content){
    let tempSchedule = content.schedule

    // remove duplicate events (ie when there is break between them)
    schedule = []
    tempSchedule.forEach((elem,i) => {

      if (!Object.keys(elem).includes('link')){
        return
      } else 
      if ((schedule.length >> 0) && (schedule.find(sched => sched.link === elem.link))){
        let newIndex = schedule.findIndex(sched => sched.link === elem.link)
        const newTime = {time : `${schedule[newIndex].time}, ${elem.time}`, start : `${schedule[newIndex].start}, ${elem.start}`}
        schedule[newIndex] = {...schedule[newIndex], ...newTime}
      } else {
        schedule.push(elem)
      }
    })


    moduleID = schedule.findIndex(event => event.link === link)

    const prevModules = schedule.slice(0, moduleID).reverse()
    const nextModules = schedule.slice(moduleID + 1)

    const prevFind = prevModules.findIndex( mod => mod.url )
    if (prevFind === -1){
      prev = undefined
    } else {
      prev = prevModules[prevFind].title
      prevLink = prevModules[prevFind].link
    }

    const nextFind = nextModules.findIndex( mod => mod.url )
    if (nextFind === -1){
      next = undefined
    } else {
      next = nextModules[nextFind].title
      nextLink = nextModules[nextFind].link
    }

  } else {
    next = info.nextDay
    nextLink = info.nextLink
    prev = info.prevDay
    prevLink = info.prevLink
    update = info.setDay
  }

  let endUrl
  if (content){
    endUrl = (content.endUrl) ? content.endUrl : undefined
  }

  return(
    <NotesPageWrapper className="notes-page">
      <NotesPageSpace className="notes-page-space" id="content-top">
        <NotesSpace className="notes-space">
          {(props.type === "Day" && content) &&
            <DayNotes 
              url = { content.url }
              endUrl = { endUrl }
              title = { content.title }
              courseTitle = { courseTitle }
              day = { dayID }
              schedule = { content.schedule }
              currentModule = { content.currentModule }
              blueJeans = { blueJeans }
              tmate = { tmate }
              jupyter = { jupyter }
              dayLink = { link }
            />
          }
          
          {(props.type === "Notes" && content) &&
            <ModuleNotes className="notes-space"
              url = { schedule[moduleID].url }
              endUrl = { endUrl }
              title = { schedule[moduleID].title }
              courseTitle = { courseTitle }
              start = { schedule[moduleID].start }
              time = { schedule[moduleID].time }
              volunteer = { schedule[moduleID].volunteer }
              noteTaker = { schedule[moduleID].noteTaker }
              currentModule = { content.currentModule }
              dayTitle = { content.title }
              day = { dayID + 1}
              blueJeans = { blueJeans }
              tmate = { tmate }
              jupyter = { jupyter }
              moduleLink = { link }
              dayLink = { link }
            />
          }
        </NotesSpace>
      </NotesPageSpace>

      {content &&
        <AsideNav 
          border="top" 
          nextLabel = { next }
          nextLink = { nextLink }
          prevLabel = { prev }
          prevLink = { prevLink }
          updateDay = { update }
          label = { props.type }
          day = { dayID + 1 }
          title = { content.title }
          dayLink = { content.link }
        />
      }
    </NotesPageWrapper>
  )
}

export default NotesPage
