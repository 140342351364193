// components
import ScheduleEntry from './ScheduleEntry'
import Notes from './Notes'
import {IconLink} from './IconLink'
import Breadcrumbs from './Breadcrumbs'
import SidebarSchedule from './SidebarSchedule'

// styles
import { DayTitle, CourseTitle, DayTitleSpace } from '../styles/dayNotes.js'
import { HeaderLinks } from '../styles/notesPage'
import { TwoColSpace, PageContent, SideBar, CourseInfo, Date, Title } from '../styles/page'

// hooks
import useFetchPageContent from "../hooks/useFetchPageContent"

function DayNotes(props) {
  const courseInfo = useFetchPageContent("courseInfo", props.file)

  const dayText = `${ props.title }`

  const breadcrumb = <Breadcrumbs 
    course = { props.courseTitle }
    courseLink = '/course/python'
    dayText = { dayText }
  />

  let schedule
  if (props.schedule){
    schedule = props.schedule.map((entry, i) => {
      return <ScheduleEntry 
        url = { entry.url }
        offsiteLink = { entry.offsiteLink }
        title = { entry.title }
        start = { entry.start }
        time = { entry.time }
        volunteer = { entry.volunteer }
        noteTaker = { entry.noteTaker } 
        updateModule = { props.updateModule }
        key = { i }
        current = { props.currentModule === i }
        module = { i }
        day = { props.day }
        link = { entry.link }
      />
    })
  }

  return (
    <>
    <DayTitleSpace className="day-title-space">
      <div className="title">
        <CourseTitle className="course-title">{ props.courseTitle }</CourseTitle>
        <DayTitle className="day-title">{ props.title }</DayTitle>
      </div>
      {(props.blueJeans || props.tmate || props.jupyter) &&
        <HeaderLinks>
          { props.blueJeans && 
            <IconLink 
              text="BlueJeans"
              icon="video"
              link={ props.blueJeans }
              target="_blank"
            />
          }
          { props.tmate && 
            <IconLink 
              text="tmate"
              icon="terminal"
              link={ props.tmate }
              target="_blank"
            />
          }
          { props.jupyter && 
            <IconLink 
              text="Jupyter"
              icon="python"
              link={ props.jupyter }
              target="_blank"
            />
          }
        </HeaderLinks> 
      }
    </DayTitleSpace>
    
    { breadcrumb }

    <TwoColSpace className="notes-space">
      <SideBar className="side-bar">
        <SidebarSchedule
          title="Schedule"
        >
          { schedule }
        </SidebarSchedule>
        <CourseInfo className="course-info"><Notes>{ courseInfo.info }</Notes></CourseInfo>
      </SideBar>
      <PageContent className="page-content">
        <Notes
          file = { props.file }
          update = { true }
          type="notes"
        >
          { props.url }
        </Notes>
        
        {props.endUrl &&
          <Notes
            file = { props.file }
            type="notes"
          >
            {props.endUrl}
          </Notes>
        }
      </PageContent>

    
    </TwoColSpace>
    </>
  )
}

export default DayNotes
