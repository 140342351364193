import React from 'react'

// components
// import UpdateMessage from './UpdateMessage'
import CopyPopUp from "./CopyPopUp";

//styles
import { NotesContentWrapper, NotesCode } from '../styles/notesContent'

// markdown
import ReactMarkdown from 'react-markdown'
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import gfm from 'remark-gfm'

// hooks
import useCheckForUpdate from '../hooks/useCheckForUpdate'
import useRefreshContent from '../hooks/useRefreshContent'

// ace editor
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";

function Notes(props){
  const refreshUpdate = useRefreshContent(props.children)
  // const checkUpdate = useCheckForUpdate(props.children)

  // taken from https://github.com/remarkjs/react-markdown/issues/69
  function flatten(text, child) {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text)
  }

  const renderers = {
    code : ({value}) => {
      return <NotesCode className="notes-code">
        <AceEditor
          mode = "python"
          theme = "github"
          value = {value}
          width = "100%"
          maxLines = { (value) ? value.split(/\r\n|\r|\n/).length : 1  }
          readOnly = {true}
          showPrintMargin = {false}
          highlightActiveLine = {false}
          fontSize = {14}
          style = {{"lineHeight": "1.6em", "fontFamily" : "'Roboto Mono', monospace"}}
        />
        <CopyPopUp
          copyText = { value }
        />
      </NotesCode>
    },
    heading : (props) => {
      // taken from https://github.com/remarkjs/react-markdown/issues/69
      var children = React.Children.toArray(props.children)
      var text = children.reduce(flatten, '')
      var slug = text.toLowerCase().replace(/\W/g, '-')
      return React.createElement('h' + props.level, {id: slug}, props.children)
    }
  }


  // const timeMessage = "Updated notes available"

  // const refreshContent = () =>{
  //   checkUpdate.setNeedsUpdate(false)
  //   refreshUpdate.setHasUpdated(true)
  // }

  return(
    <NotesContentWrapper type={props.type}>
      {/* {(checkUpdate.needsUpdate && props.update) &&
        <UpdateMessage
          timeMessage = { timeMessage }
          refreshContent = { refreshContent }
        />
      } */}

      { props.breadcrumb && props.breadcrumb }
      { props.before && props.before}

      <ReactMarkdownWithHtml
        plugins={[gfm]}
        renderers={renderers}
        allowDangerousHtml = { true }
      >
        { refreshUpdate.content }
        </ReactMarkdownWithHtml>

      { props.after && props.after}
    </NotesContentWrapper>
  )
}

export default Notes
