// dimensions in design
const appDimensions = {
  maxWidthBottomDrawer : "1400px",
  maxWidthNoDrawers : "1878px",
  minWidthGrowSideDrawer : 1400 + 20 + 20 + 475 + "px",
  breakToSmallScreen : 475 + 20 + 475 + 20,
  asideNavHeight : 74
}

const toggleBtnDimensions = {
  toggleBtnDim : "24px",
  iconDim : "13px",
  borderRadius : "4px",
  btnMargin : "10px"
}

const headerDimensions = {
  headerHeight : ["47px", "71px", "66px"],

  // breakpoints
  twoLineBPmax : "845px",
  twoLineBPmin : "843px",
  shrinkSizeBP : "600px",
}

export { appDimensions, toggleBtnDimensions, headerDimensions }