// Clipboard
import Clipboard from 'react-clipboard.js';

// hooks
import useShowPopUp from '../hooks/useShowPopUp'

// styled
import { PopUpAlert, CopyCode } from '../styles/copyPopUp'

function CopyPopUp(props){
  const copyMessage = useShowPopUp()

  const popupElement = copyMessage.showPopUp ? "show-popup" : ""
  return (
    <CopyCode 
      className = { popupElement }
    >
      {copyMessage.showPopUp &&
        <PopUpAlert>
          copied
        </PopUpAlert>
      }
      <Clipboard 
        data-clipboard-text={ props.copyText } 
        className="copy-code"
        onSuccess={ copyMessage.togglePopUp }
      >
        copy
      </Clipboard>
    </CopyCode>
  )
}

export default CopyPopUp