import { useState, useEffect } from 'react'

function useFetchPageContent(key, file){
  const [info, setInfo] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(file)
      const data = await result.json()

      setInfo(data[key])
    }
    fetchData()
  }, [])

  return {info}
}

export default useFetchPageContent