import logo from "../images/logo.png"
import { Link } from "react-router-dom"

import Login from "./Login"

// styled
import { HeaderTag, CourseInfo } from "../styles/header"

/**
 * Header for the app
 */

function Header(props) {

  return (
    <HeaderTag className="header">

      <CourseInfo className="course-info">
        <Link to="/">
          <img src={logo} alt="Don't Use This Code" />
        </Link>
      </CourseInfo>

      {/*
      <Login 
        login = { props.login }
        onLogin = { props.onLogin }
        onLogout = { props.onLogout }
      />
      */}

    </HeaderTag>
  )
}

export default Header
