import { useParams } from "react-router-dom"
import { useState, useEffect } from 'react'


function ForceLogin(props){
  let { course } = useParams()
  const email = "annonymous"

  const [files, setFiles] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const coursesResult = await fetch("/login/courses.json")
      const coursesData = await coursesResult.json()

      // courseFiles.current = coursesData
      setFiles(coursesData)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (files){
      props.onLogin({"email": email, "file" :  files[course]})
    }
  }, [files])

  return(
    <p>Logging into {course}</p>
  )
}

export default ForceLogin