import styled from "styled-components";
import { colors } from './colors'

import right from "../Icons/right.svg"

const BreadcrumbList = styled.ul`
  list-style: none;
  margin: 10px 20px;
  padding: 10px 0;
  border-bottom: solid 1px ${colors.defaultBorder};
  li {
    display: inline-block;
    /* margin-right: 10px; */
    :not(:last-of-type)::after{
      mask: url('${right}');
      mask-size: cover;
      content: "";
      display: inline-block;
      margin: 0 6px;
      background-color: ${colors.breadcrumbCaret};
      height: 10px;
      width: 6px;
    }
  }
`

export { BreadcrumbList }