// styles
import { BreadcrumbList } from "../styles/breadcrumbs"

function Breadcrumbs(props){
  let day, module = ""
  const course = <li><a href={ props.courseLink }>{ props.course }</a></li>
  if (props.dayText) {
    day = props.dayLink ? <li><a href={ props.dayLink }>{ props.dayText }</a></li> : <li>{ props.dayText }</li>
  }
  if (props.moduleText) {
    module = props.moduleLink ? <li><a href={ props.moduleLink }>{ props.moduleText }</a></li> : <li>{ props.moduleText }</li>
  }

  return(
    <BreadcrumbList className="breadcrumb-list">
      { course }
      { day }
      { module }
    </BreadcrumbList>
  )
}

export default Breadcrumbs