import { useState } from 'react';

// show / hide popup modal

function useShowModal(init){
  const [showModal, setShowModal] = useState(init);

  return { showModal, setShowModal }
}

export default useShowModal