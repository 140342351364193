import styled from "styled-components";

import { colors } from './colors'
import { weight, fontSize } from './typography'

const ScheduleTitle = styled.h4`
  color: white;
  background-color: ${colors.asideTitle};
  padding: 10px 15px 10px 30px;
  /* font-weight: ${weight.regular}; */
  font-size: ${fontSize.p};
  border-radius: 4px 4px 0 0;
  margin: 0;
  line-height: 1em;
`

const ScheduleSpace = styled.div`
  border-radius: 0 0 4px 4px;
  padding: 1px 15px;
  background-color: ${colors.scheduleBG};
`

export { ScheduleTitle, ScheduleSpace }