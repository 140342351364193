import styled from "styled-components";
import { colors } from './colors'

const ModalBG = styled.div`
  background-color: ${colors.modalBG};
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: -1px 0 4px rgba(0, 0, 0, 0.17);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
`

const ModalClose = styled.button`
  display: block;
  border: none;
  padding: 0px;
  align-self: flex-end;
  background: none;
  svg{
    height: 15px;
    width: 15px;
  }
`
const ModalBtn = styled.button`
  border: solid 1px ${colors.corporateHeader};
  background-color: white;
  padding: 7px 40px;
  border-radius: 4px;
  background: linear-gradient(to right, ${colors.linkColor} 0%, ${colors.linkColor} 100%);
  background-position: 0 100%;
  background-repeat: repeat-y;
  background-size: 0px 0px;
  transition: all .2s;
  transition-delay: .1s;
  :hover {
    color: white;
    background-size: 100% 4px;
  }
`

export { ModalBG, ModalContent, ModalClose, ModalBtn }