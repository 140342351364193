import styled from "styled-components";

import { colors } from './colors'
import { weight, fontSize } from './typography'
import { headerDimensions } from './dimensions'
import leftArrow from "../Icons/arrow-left.svg"

import {Link} from "react-router-dom"

const ModuleTitle = styled.h1`
  font-weight: ${weight.regular};
  font-size: ${fontSize.h4};
  line-height: 2em;
  color: white;
  margin: 0;
`

const ModuleHeader = styled.div`
  border-bottom: solid 1px ${colors.defaultBorder};
  width: 100%;
  padding: 0 20px 10px 20px;
  background-color: ${colors.asideTitle};
  padding: 10px 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.17);
  /* margin: 0 0 1em 0; */
  .module-time{
    margin-top: 0;
  }
  ul{
    color: white;
    opacity: .7;
  }
  @media (min-width: ${headerDimensions.twoLineBPmin}){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const ModuleHeaderBack = styled(Link)`
  font-weight: ${weight.bold};
  font-size: ${fontSize.small};
  color: white;
  opacity: .7;
  background: linear-gradient(to right, white 0%, white 100%);
  background-position: 0 100%;
  background-repeat: repeat-y;
  background-size: 0px 0px;
  cursor: pointer;
  ::before{
    content: "";
    mask: url('${leftArrow}');
    display: inline-block;
    height: calc(.8em + 1px);
    width: .8em;
    mask-size: cover;
    background-color:white;
    opacity: .7;
    content: "";
    margin-right: 10px;
  }
  :hover{
    opacity: 100%;
    color: ${colors.asideTitle};
    ::before{
      background-color: ${colors.asideTitle};
    }
  }
`

const ModuleVolunteers = styled.ul`
 
`

export { ModuleTitle, ModuleHeader, ModuleHeaderBack, ModuleVolunteers }
