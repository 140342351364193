import styled, { css } from "styled-components";

import { appDimensions, headerDimensions } from "./dimensions"
import { colors } from "./colors"

const LearningPlatformApp = styled.div`
  display: flex;
  flex-direction: column;
`
const MainSpace = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  
  align-content: center;
  @media (min-width: ${headerDimensions.twoLineBPmin}){
    height: calc(100vh - ${headerDimensions.headerHeight[0]});
  }
  @media (max-width: ${headerDimensions.twoLineBPmax}){
    height: calc(100vh - ${headerDimensions.headerHeight[1]});
  }
  @media (max-width: ${headerDimensions.shrinkSizeBP}){
    height: calc(100vh - ${headerDimensions.headerHeight[2]});
  }
`

const EmbedSpace = styled.div`
  flex-grow: 1;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background-color: ${colors.embedBG};

  @media (min-width: ${appDimensions.breakToSmallScreen + 1}px){
    justify-content: flex-end;
  }
  @media (max-width: ${appDimensions.breakToSmallScreen}px){
    justify-content: space-between;
  }

  /* when the side drawer is hidden */
  ${(props) => props.showSideDrawer === false &&
  css`
    align-items: center;
  `}
`

export { LearningPlatformApp, EmbedSpace, MainSpace}  