import styled from "styled-components";

import { colors } from './colors'
import { weight, fontSize } from './typography'
import { headerDimensions } from './dimensions'

const DayTitle = styled.h1`
  font-weight: ${weight.regular};
  font-size: ${fontSize.h4};
  line-height: 2em;
  color: white;
  margin: 0;
`

const CourseTitle = styled.h5`
  font-weight: ${weight.regular};
  font-size: ${fontSize.p};
  line-height: 1.5em;
  color: rgba(255,255,255,.7);
  margin: 0;
`

const DayTitleSpace = styled.div`
  background-color: ${colors.asideTitle};
  padding: 10px 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.17);
  @media (min-width: ${headerDimensions.twoLineBPmin}){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export { DayTitle, DayTitleSpace, CourseTitle }