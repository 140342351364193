import styled from "styled-components";
import { weight } from './typography'
import { colors } from './colors'

const LoginLink = styled.button`
  border: none;
  padding: 0;
  /* font-weight: ${weight.bold}; */
  line-height: 1em;
  color: white;
  background: linear-gradient(to right, white 0%, white 100%);
  background-position: 0 100%;
  background-repeat: repeat-y;
  background-size: 0px 0px;
  transition: all .2s;
  transition-delay: .1s;
  :hover {
    color: ${colors.headerBG};
    background-size: 100% 4px;
  }
  :focus{
    outline: none;
  }
`
export { LoginLink }

