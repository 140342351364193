import styled from "styled-components"
import { colors } from "./colors"

const LoginButtonStyle = styled.button`
  border: solid 1px white;
  background-color: white;
  color: white;
  padding: 7px 40px;
  border-radius: 4px;
  margin: 0 20px;
  background: linear-gradient(to right, ${colors.linkColor} 0%, ${colors.linkColor} 100%);
  background-position: 0 100%;
  background-repeat: repeat-y;
  background-size: 0px 0px;
  transition: all .2s;
  transition-delay: .1s;
  :hover {
    color: white;
    background-size: 100% 4px;
  }
`

export default LoginButtonStyle