import { colors } from './colors'
import { headerDimensions } from './dimensions'
import styled from "styled-components";

const HeaderTag = styled.header`
  background-color: ${colors.headerBG};
  color: ${colors.headerText};
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;

  @media (max-width: ${headerDimensions.twoLineBPmax}){
    flex-direction: column;
    align-items: center;
  }

  img{
    transition: all .2s;
    width: 335px;
    height: 20px;
    @media (min-width: ${headerDimensions.twoLineBPmin}){
      margin-right: 20px;
    }
    @media (max-width: ${headerDimensions.twoLineBPmax}){
      margin-bottom: 4px;
    }
    @media (max-width: ${headerDimensions.shrinkSizeBP}){
      width: 252px;
      height: 15px;
    }
  }

  a{
    background: none;
    height: 20px;
  }

  h2{
    color: ${colors.headerText};
    transition: all .2s;
    padding: 0;
    margin: 0;
    line-height: 21px;
    font-size: 18px;
    font-weight: normal;
    opacity: .7;
    @media (max-width: ${headerDimensions.shrinkSizeBP}){
      font-size: 14px;
    }
  }
`

const CourseInfo = styled.div`
  display: flex;
  @media (max-width: ${headerDimensions.twoLineBPmax}){
    flex-direction: column;
    align-items: center;
  }
`

export { HeaderTag, CourseInfo }