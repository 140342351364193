import styled, { css } from "styled-components";

import { colors } from './colors'
import { weight } from "./typography"

const NotesContentWrapper = styled.div`
  padding: 0 20px;
  position: relative;

  .ace_editor{
    border: solid 1px ${colors.defaultBorder}; 
  }

  pre{
    margin-bottom: .7em;
  }

  pre code{
    padding: .5em 20px;
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
  }

  ${props => props.type === "notes" && 
  css`
    h2{
      border-bottom: solid 1px rgba(25, 108, 109, .4);
      padding-bottom: 7px;
      margin: 35px 0 10px 0;
      color: ${colors.defaultText}
    }
    h1{
      color: ${colors.notesHeadline};
      font-weight: ${weight.regular};
      font-size: 2em;
    }
  `}
`

const NotesCode = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
`

export { NotesContentWrapper, NotesCode }