export const colorPaletteOrig = {
  sweetBrown : "#a73334",
  wine : "#723439",
  skobeloff : "#196C6D",
  charcoal : "#36454f",
  storm : "#03506f",
  cgBlue : "#207CA0",
}

// color shorthand
export const colorPalette = {
  red : colorPaletteOrig.sweetBrown,
  wine : colorPaletteOrig.wine,
  green : colorPaletteOrig.skobeloff,
  charcoal : colorPaletteOrig.charcoal,
  grey : colorPaletteOrig.storm,
  blue : colorPaletteOrig.cgBlue,
  darkBlue : "#0D1929",
  gray: ["#3f3f3f", "#c4c4c4", "#f2f2f2"],
  white : "#ffffff",
  black : "#000000",
  alphaWhite : "rgba(255,255,255,.7)",
  alphaGray : "rgba(63, 63, 63, .7)"
}

// app colors
export const colors = {
  linkColor : colorPalette.blue,
  defaultText : colorPalette.gray[0],
  defaultBorder : colorPalette.gray[1],
  background : colorPalette.white,
  headerBG : colorPalette.charcoal,
  headerText : colorPalette.white,
  placeholder : colorPalette.gray[1],
  toggleBtnBG : colorPalette.grey,
  toggleBtnIcon : colorPalette.white,
  navLinkHover : colorPalette.gray[2],
  asideNavBG : colorPalette.white,
  scheduleText : colorPalette.black,
  scheduleBorder : colorPalette.gray[1],
  embedBG : colorPalette.gray[2],
  asideTitle : colorPalette.grey,
  tableHead : colorPalette.charcoal,
  tableStripe : colorPalette.gray[2],
  codeColor : "#F0F0F0",
  mailingList : colorPalette.grey,
  headerLink : colorPalette.alphaWhite,
  corporateHeader : colorPalette.blue,
  corporateCourseBG : colorPalette.blue,
  corporateColorText : colorPalette.alphaWhite,
  modalBG : colorPalette.alphaGray,
  formLabel : colorPalette.gray[0],
  formInputBG : colorPalette.gray[2],
  copyPopUpBG : colorPalette.gray[0],
  breadcrumbCaret : colorPalette.grey,
  corporateIntroBG : colorPalette.darkBlue,
  corporateIntroText : colorPalette.alphaWhite,
  teaSteamDot : colorPalette.blue,
  alertBG : colorPalette.red,
  scheduleBG : colorPalette.gray[2],
  notesHeadline : colorPalette.charcoal
}
