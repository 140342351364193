import { colors } from './colors'
import { fontSize } from './typography'
import styled, { css } from "styled-components";
import clock from "../Icons/clock.svg"

const ScheuleEntryStyle = styled.div`
  margin: 20px 0;
  color: ${colors.scheduleText};
  

  ${(props) => props.isModule === "notModule" &&
  css`
    padding: 0 15px;
  `}

  ${(props) => props.isModule === "module" &&
  css`
    padding: 15px;
    /* border: solid 1px ${colors.scheduleBorder}; */
    border-radius: 4px;
    background-color: white;
  `}
`

const ScheduleTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${fontSize.h4};
  line-height: 1.4em;

  ${(props) => props.current &&
  css`
    ::before{
      vertical-align: middle;
      content: "";
      mask: url('${clock}');
      margin-right: 10px;
      height: 1em;
      background-color: ${colors.linkColor};
      display: inline-block;
      width: 1em;
    }
  `}
`

const ScheduleTime = styled.ul`
  font-size: ${fontSize.small};
  list-style-type: none;
  margin: 8px 0 0 0;
  padding: 0;

  li{
    display: inline-block;
    margin-right: 20px;
  }
`

const ScheduleVolunteer = styled.ul`
  font-size: ${fontSize.small};
  list-style-type: none;
  margin: 8px 0 0 0;
  padding: 8px 0 0 0;
  border-top: solid 1px ${colors.scheduleBorder};
  li{
    margin-top: 4px;
  }
`

export { ScheuleEntryStyle, ScheduleTitle, ScheduleTime, ScheduleVolunteer }