import { fontSize, weight } from './typography'
import styled from "styled-components";

const IconLinkSpace = styled.div`
  font-size: ${fontSize.small};
  svg{
    color: black;
    height: 1em;
    margin-right: 1em;
  }
  a{
    font-weight: ${ weight.regular }
  }
`

export { IconLinkSpace }