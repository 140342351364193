//components
import Notes from './Notes'

// styles
import { NotesPageWrapper, NotesPageSpace } from '../styles/notesPage'
import { TwoColSpace, PageContent } from '../styles/page'

// hooks
import useFetchPageContent from "../hooks/useFetchPageContent"

function Page(props){
  const pageContent = useFetchPageContent( props.content, props.file )

  return (
    <NotesPageWrapper className="notes-page">
      <NotesPageSpace className="notes-page-space">

        <TwoColSpace>
            {pageContent.info && 
              <PageContent>
                <Notes>{ pageContent.info.url }</Notes>
              </PageContent>
            }
        </TwoColSpace>

      </NotesPageSpace>
    </NotesPageWrapper>
  )
}

export default Page