import { colors } from './colors'
import styled from "styled-components";
import envelope from "../Icons/envelope.svg"
import { weight } from './typography'
import { headerDimensions } from './dimensions'

const TwoColSpace = styled.div`
  padding: 20px 10px;
  @media (min-width: ${headerDimensions.twoLineBPmin}){
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`


const PageContent = styled.article`
  margin-right: 20px;
  flex-grow: 2;
  flex-basis: 0;
`

const SideBar = styled.aside `
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 10px;
`

const MailingList = styled.div`
  background-color: ${colors.mailingList};
  padding: 10px 0;
  color: white;
  border-radius: 4px;
  h2{
    color: white;
    line-height: 1em;
    ::before{
      mask: url('${envelope}');
      display: inline-block;
      height: calc(1em);
      width: calc(1em);
      mask-size: cover;
      background-color: white;
      content: "";
      margin-right: 10px;
      vertical-align: bottom;
      :focus{
        outline: none;
      }
    }
  }
  a{
    background-color: white;
    border-radius: 4px;
    color: ${colors.mailingList};
    padding: 7px 40px;
    :hover{
      color: white;
    }
  }
  p{
    margin: 30px 0;
  }
  p:last-of-type{
    margin-bottom: 17px;
  }
`
const CourseInfo = styled.div`
  margin: 40px 0;
`

const Date = styled.h3`
  font-weight: ${weight.regular};
  margin: 0;
`

const Title = styled.h1`
  font-size: 24px;
  margin: 10px 0 30px 0;
  &.page{
    margin-left: 20px;
  }
`

export { TwoColSpace, PageContent, SideBar, MailingList, CourseInfo, Date, Title }