import { LoginLink } from "../styles/login"

function Login(props){
  const loginText = props.login ? "Log Out" : "Log In"
  const onClick = props.login ? event => props.onLogout() : event => props.onLogin(true)

  return(
    <LoginLink onClick={ onClick }>{ loginText }</LoginLink>
  )
}

export default Login