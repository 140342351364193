import { useState, useEffect, useRef } from 'react'

function useGetDay(link, file){

  // get content
  const [ prevDay, setPrevDay ] = useState()
  const [ nextDay, setNextDay ] = useState()
  const [ prevLink, setPrevLink ] = useState()
  const [ nextLink, setNextLink ] = useState()
  const [ currentModule, setCurrentModule ] = useState()
  const [ content, setContent ] = useState()

  const currDayIndex = useRef()

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(file)
      const data = await result.json()

      let dayIndex = data.schedule.findIndex(day => day.link === link)

      if (dayIndex === -1){
        dayIndex = data.schedule.findIndex(day => (day.schedule.findIndex(event => event.link === link) !== -1))
      }

      currDayIndex.current = dayIndex

      setContent(data.schedule[dayIndex])

      if (dayIndex-1 >= 0){
        setPrevDay(data.schedule[dayIndex-1].title)
        setPrevLink(data.schedule[dayIndex-1].link)
      } else {
        setPrevDay(undefined)
      }
      if (dayIndex+1 < data.schedule.length && data.schedule[dayIndex+1].type == "workshop"){
        setNextDay(data.schedule[dayIndex+1].title)
        setNextLink(data.schedule[dayIndex+1].link)
      } else {
        setNextDay(undefined)
      }

      if (dayIndex === data.currentDay){
        setCurrentModule(data.currentModule)
      } else {
        setCurrentModule(null)
      }
    }
    fetchData()
  }, [link])

  return { content, prevDay, nextDay, prevLink, nextLink, currentModule, currDayIndex }
}

export default useGetDay
