import { NotesPageWrapper, NotesPageSpace } from '../styles/notesPage'
import { TwoColSpace, PageContent, Title } from '../styles/page'

function NoMatch(){
  return (
    <NotesPageWrapper className="notes-page">
      <NotesPageSpace className="notes-page-space">

        <TwoColSpace>
          <PageContent>
            <Title>404</Title>
            <h5>File Not Found</h5>
          </PageContent>
        </TwoColSpace>

      </NotesPageSpace>
    </NotesPageWrapper>
  )
}

export default NoMatch