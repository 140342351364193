import { useState, useEffect, useRef } from 'react'

import { ModalBtn } from "../styles/modal"

function LoginForm(props){
  const [email, setEmail] = useState("")
  const [course, setCourse] = useState("")

  const [showError, setShowError] = useState(false)

  const validEmails = useRef()
  const emailLogins = useRef()
  const courseFiles = useRef()

  const handleSubmit = (event, loginText, courseInput) => {
    event.preventDefault()

    const errors = []

    const checkEmail = validEmails.current.includes(loginText)
    const checkCourse = (checkEmail && emailLogins.current[loginText].courses.includes(courseInput))
    const validateCourse = (checkCourse && Object.keys(courseFiles.current).includes(courseInput))

    if (!checkEmail){
      errors.push("Email address not registered for course")
    }
    if(!checkCourse){
      errors.push("Course ID not associated with email address")
    }
    if(!validateCourse){
      errors.push("Course not listed on platform")
    }

    // valid email address
    if (checkEmail && checkCourse && validateCourse){
      props.onLogin({"email": loginText, "file" : courseFiles.current[courseInput]})
      props.onClose(false)
    } else {
      setShowError(errors)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch("/login/login.json")
      const data = await result.json()

      const coursesResult = await fetch("/login/courses.json")
      const coursesData = await coursesResult.json()

      validEmails.current = Object.keys(data)
      emailLogins.current = data
      courseFiles.current = coursesData
    }
    
    fetchData()
  }, [])
  
  let errorMsg
  if (showError){
    errorMsg = showError.map((err, i) => <li key={i}> {err} </li>)
  }


  return(
    <form action="">  
      {showError &&
        <ul>{ errorMsg }</ul>
      }
      <fieldset>

        <label htmlFor="email">Email</label>
        <input 
          type="text" 
          id="email" 
          value={ email }
          onChange = {event => setEmail(event.target.value)}
        />
      </fieldset>

      <fieldset>
        <label htmlFor="course">Course ID</label>
        <input 
          type="text" 
          id="course" 
          value={ course }
          onChange = {event => setCourse(event.target.value)}
        />
      </fieldset>

      <ModalBtn
        onClick = { e => handleSubmit(e, email, course) }
      >
        Login
      </ModalBtn>
    </form>
  )
}

export default LoginForm